import React, { Component } from "react";
import styled from "styled-components";
import { withPage } from "../../Page";

const imgs = [
  "/images/pages/instruction/android.jpg",
  "/images/pages/instruction/ios.jpg"
];

const imgsRWD = [
  "/images/pages/instruction/android-rwd1.jpg",
  "/images/pages/instruction/android-rwd2.jpg",
  "/images/pages/instruction/android-rwd3.jpg",
  "/images/pages/instruction/ios-rwd1.jpg",
  "/images/pages/instruction/ios-rwd2.jpg",
  "/images/pages/instruction/ios-rwd3.jpg"
];

class InstructionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileDevice: false
    };
  }

  componentDidMount() {
    let screenWidth = window.screen.width;
    if (screenWidth <= 499) {
      this.setState({ mobileDevice: true });
    }
  }

  render() {
    let { mobileDevice } = this.state;

    return (
      <Wrapper>
        <Container>
          <h3>使用說明</h3>
          <h4>注意事項</h4>
          <ol style={{ marginBottom: 10 }}>
            <li>
              插卡後開啟”數據漫遊”及”行動數據”即可使用，並請於包裝上所載註之有效期限內使用完畢。
            </li>
            <li>
              此產品適用於4G /
              3G之智慧型手機，僅提供行動數據服務，並不支援語音、短信及其他服務。
            </li>
            <li>
              此產品為三合一SIM卡，可以適用於所有智慧型手機。若為雙卡機，則請將此商品插入SIM1卡槽。
            </li>
            <li>
              此產品之行動數據服務只適用於包裝上所載註之地區，如處於4G覆蓋以外之位置，指定網路供應商仍會透過3G網路提供服務。包裝上未載註之地區，不支援行動數據服務。
            </li>
            <li>
              於產品有效期限內使用之指定網路供應商而產生之數據用量，將會從商品中的數據用量扣除。
            </li>
            <li>
              此產品因過期，或是不正常使用的情況下，導致卡片失效而被終止服務，未用完之數據用量將不提供退還或轉讓。
            </li>
            <li>
              行動服務之實際連線速度，會因為用戶與行動基地台之相對位置、網站伺服器資源及網路流量狀態、用戶數量、流動裝置的最高支援速度、電腦軟硬體設備等因素而有所影響。
            </li>
          </ol>
          {!mobileDevice
            ? imgs.map((b, i) => (
                <img key={"img" + i} src={b} className="desktop" />
              ))
            : imgsRWD.map((b, i) => (
                <img key={"imgRWD" + i} src={b} className="mobile" />
              ))}
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 20px 0;
  background-color: #ededed;
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  padding: 50px;

  & > h3 {
    padding: 20px 0px;
    color: #5a5a5a;
  }

  & > h4 {
    padding: 20px 0px;
    color: #5a5a5a;
  }

  & > p,
  li {
    color: #6b6b6b;
    font-size: 15px;
    line-height: 30px;
    list-style-position: inside;
  }

  & > .desktop {
    width: 700px;
    height: 514px;

    @media screen and (max-width: 700px) {
      width: 500px;
      height: 375px;
    }
  }

  & > .mobile {
    width: 300px;
    height: 547px;

    @media screen and (max-width: 320px) {
      width: 250px;
      height: 456px;
    }
  }
`;

export default withPage(InstructionPage);
